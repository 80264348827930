export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $api } = useNuxtApp();

  const { logPageView } = useAnalytics();

  const { token } = from.params;

  logPageView('Verify email');

  const { data, status } = await $api.user.verifyEmail({ token });

  const { message } = data;

  const { showAlert } = useAlertStore();
  showAlert(message);

  return navigateTo({ name: 'index' });
});
